<template>
  <Layout>
    <div class="container is-fullhd">
        <div v-if="isDataReady">
          <canvas-panel :form-data="formData" :record-data="recordData" />
        </div>
    </div>
    <modal
      v-show="showModalConfirmation"
      @closeModal="closeModal"
      v-bind="modalConfig"
    />
  </Layout>
</template>

<script>
import axios from 'axios';

export default {
  name: 'formRecord',
  components: {
    Layout: () => import('@/router/layouts/Main'),
    CanvasPanel: () => import('@/components/CanvasPanel')
  },
  data() {
    return {
      isDataReady: false,
      formData: {},
      showModalConfirmation: false,
      modalConfig: {
        bodyText: 'This form was deleted or you are not allowed to see it',
        titleText: 'Form was not found',
        buttons: {
          confirm: {
            text: 'Ok',
            class: 'is-primary'
          }
        }
      },
      recordData: {},
      formId: this.$route.params.id,
      recordId: this.$route.params.recordId
    };
  },
  methods: {
    async getFormData() {
      try {
        const response = await axios.get(
          $formsConfig.core.api.forms + this.formId
        );
        this.formData = response.data;
      } catch (error) {
        this.showModalConfirmation = true;
      }
    },
    async getRecordData() {
      try {
        const uriRecord =
          $formsConfig.core.api.records.replace('__formId__', this.formId) +
          this.recordId;
        const response = await axios.get(uriRecord);
        this.recordData = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    closeModal() {
      this.$router.push('/forms');
    }
  },
  async created() {
    if (this.recordId) {
      await this.getRecordData();
    }
    await this.getFormData();
    this.isDataReady = true;
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  > .columns {
    height: 100%;
    margin-top: 0;
    > .column {
      height: 100%;
    }
  }
}
.headers {
  font-size: 14px;
  font-weight: bold;
  color: $tittle-font;
}
</style>
